
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

@Component
export default class TimePicker extends Vue {
  @Prop(Object) readonly field?: Record<string, unknown>;
  @Prop(String) readonly label?: string;
  @Prop(String) readonly min?: string;
  @Prop(String) readonly max?: string;
  @Prop({ type: Number, default: 5 }) readonly step!: number;
  @Prop(Boolean) readonly dense?: boolean;
  @Prop({ type: String, default: "solo" }) readonly design?: string;

  @Model("input", { type: String }) readonly data!: string;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  localData: string;
  modal = false;

  rules = {
    required: this.field?.required ? Rules.required : true
  };

  constructor() {
    super();

    this.localData = this.data ?? "";
  }

  get formattedLocalData() {
    return this.localData ? moment(this.localData).format("DD.MM.YYYY") : null;
  }

  allowedMinutes(minute: number) {
    return minute % this.step == 0;
  }
}
